import React from "react";
import {
  FaInstagramSquare,
  FaFacebook,
  FaYoutube,
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaTelegramPlane,
  FaTelegram,
  FaLinkedin,
} from "react-icons/fa";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { BiLogoPlayStore } from "react-icons/bi";
import { IoCall } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <footer
        className="text-center text-lg-start"
        style={{ backgroundColor: "black", color: "white" }}
      >
        {/* Social icons */}
        <section className="d-flex justify-content-center justify-content-lg-between ">
          <div>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab FaTelegramPlane"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="" className="me-4 text-reset">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>

        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row d-flex justify-content-between align-items-start">
              {/* <div className="col-md-2 mb-4">
                <h6 className="fw-bold mb-4 text-center text-md-start">
                  <i className="fas fa-gem "></i>
                  <span>Quick Navigation Link</span>
                </h6>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Angular
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    React
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Vue
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Laravel
                  </a>
                </p>
              </div> */}

              {/* Social Media and Community */}
              <div className="col-12 col-md-6 col-lg-2 mb-4">
                <h6 className="fw-bold mb-4 text-center text-md-start">
                  Social Media and Community
                </h6>
                <div className="d-flex flex-column align-items-center align-items-md-start gap-0">
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://www.instagram.com/accounts/login/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaInstagramSquare style={{ fontSize: "1.3rem" }} />{" "}
                      Instagram
                    </a>
                  </p>
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://www.facebook.com/share/1GSfp9HBjE/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaFacebook style={{ fontSize: "1.3rem" }} /> Facebook
                    </a>
                  </p>
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://www.youtube.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaYoutube style={{ fontSize: "1.3rem" }} /> YouTube
                    </a>
                  </p>
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://t.me/el_tutor_app"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaTelegram style={{ fontSize: "1.3rem" }} /> Telegram
                    </a>
                  </p>
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FaLinkedin
                        style={{ fontSize: "1.2rem", marginTop: "-.4rem" }}
                      />{" "}
                      LinkedIn
                    </a>
                  </p>
                  <p className="d-flex align-items-center gap-2">
                    {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <FaLinkedinIn style={{ fontSize: '1.3rem' }} />
        LinkedIn
      </a> */}
                  </p>
                </div>
              </div>

              {/* Download the App */}
              <div className="col-12 col-md-6 col-lg-2 mb-4">
                <h6 className="fw-bold mb-4 text-center text-md-start">
                  {/* */}
                  Download the App
                </h6>
                <div className="d-flex flex-column align-items-center align-items-md-start gap-2">
                  <p className="d-flex align-items-center gap-2">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.eltutor&pcampaignid=web_share"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <BiLogoPlayStore style={{ fontSize: "2rem" }} /> Google
                      Play
                    </a>
                  </p>
                </div>
              </div>

              {/* Legal Information */}
              <div className="col-12 col-md-6 col-lg-2 mb-4">
                <h6 className="fw-bold mb-4 text-center text-md-start">
                  Legal Information
                </h6>
                <div className="d-flex flex-column align-items-center align-items-md-start gap-0">
                  <p>
                    <a
                      href="https://www.termsfeed.com/live/83f6aab7-728b-4a82-b23e-b2c44efcab4f"
                      // target="_blank"
                      // rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                      className="text-reset text-decoration-none"
                    >
                      Terms & Conditions
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.termsfeed.com/live/dcff4833-e3e2-440a-81f5-9e97249c66ff"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                      className="text-reset text-decoration-none"
                    >
                      Privacy Policy
                    </a>
                  </p>
                </div>
              </div>

              {/* Contact */}
              <div className="col-12 col-md-6 col-lg-4 mb-4">
                <h6 className="fw-bold mb-4 text-center text-md-start">
                  Contact
                </h6>
                <div className="d-flex flex-column align-items-center align-items-md-start gap-0">
                  <p className="d-flex justify-content-center justify-content-md-start gap-2 footer">
                    <CiLocationOn style={{ fontSize: "3rem", marginTop: -8 }} />
                    <span className="text-center text-md-start">
                      <span>
                        {" "}
                        <span className="fs-5" style={{ fontWeight: "bold" }}>
                          IPELU TUTOR PRIVATE LIMITED
                        </span>
                        <br /> Trust colony Malaypur Near Jamui Rly. Station
                        At+PO- Malaypur Dist-Jamui,Bihar Pin 811313{" "}
                      </span>
                    </span>
                  </p>

                  <p className="d-flex justify-content-center justify-content-md-start gap-2">
                    <IoCall className="mt-1" style={{ fontSize: "1.3rem" }} />

                    <a
                      href="tel:+9006912555"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <span>+91 9006912555</span>
                    </a>
                  </p>

                  <p className="d-flex justify-content-center justify-content-md-start gap-2">
                    <CiMail className="mt-1" style={{ fontSize: "1.5rem" }} />
                    <a
                      href="mailto:indrajeetpandey950@gmail.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <span className="ms-2">support@eltutor.com</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="">
          <div class="container p-4 border-top">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-2 col-md-3 col-sm-12 d-flex justify-content-center">
                <a
                  href="https://www.termsfeed.com/live/dcff4833-e3e2-440a-81f5-9e97249c66ff"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <p className="text-grey fs-8">Privacy Policy</p>
                </a>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12 d-flex justify-content-center">
                <a
                  href="https://www.termsfeed.com/live/b100c542-023a-48d6-b7ae-7245600dc8e3"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-reset text-decoration-none"
                >
                  <p className="text-grey fs-8">Terms of Use</p>
                </a>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12 d-flex justify-content-center">
                <a
                  href="https://www.termsfeed.com/live/2512cfe2-25bd-485d-853c-bf3c5e68fcbf"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-reset text-decoration-none"
                >
                  <p className="text-grey fs-8">Sales and Refunds</p>
                </a>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-12 d-flex justify-content-center">
                <a
                  href="https://www.termsfeed.com/live/2512cfe2-25bd-485d-853c-bf3c5e68fcbf"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-reset text-decoration-none"
                >
                  <p className="text-grey fs-8">Legal</p>
                </a>
              </div>
            </div>
          </div>
          <div class="container p-4 pt-0">
            <div class="row">
              <div className="col-12 d-flex justify-content-center">
                <a
                  href="https://www.termsfeed.com/live/385e82a3-4abb-46ed-8751-930d3c447ad9"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                  className="text-reset text-decoration-none"
                >
                  <p className="text-grey fs-8">
                    © 2025 EL-Tutor ( IPELU TUTOR PRIVATE LIMITED ). All Rights
                    Reserved.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";

const Homehero = () => {
  return (
    <>
      <header>
        <div className="p-5 text-center bg-image hero1" style={{
          backgroundColor: '#081A28',
          maxWidth: "100%",
          height: "95vh"
        }}
        >
          <div className="mask">
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-white">
                <h1 className="mb-3">"Find the Perfect Home Tutor for Your Needs."
                </h1>
              
                <a href="https://play.google.com/store/apps/details?id=com.eltutor&pcampaignid=web_share"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit",backgroundColor: '#25AD0F', color: '#FFF'  }} data-mdb-ripple-init className="btn btn-lg shadow-none"  role="button">Download App</a>
              </div>
            </div>
          </div>
          <section className="mt-5">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0 hero_img1">
                <img src="./assets/images/Student1.png" alt="" />
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0 hero_img2">
                <img src="./assets/images/Student2.png" alt="" />
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-md-0 hero_img3">
                <img src="./assets/images/Student3.png" alt="" />
              </div>
            </div>
          </section>
        </div>
      </header>
    </>
  );
};

export default Homehero;

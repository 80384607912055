import React, { useState } from "react";
import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { MdAddLocationAlt } from "react-icons/md";
import axios from 'axios';


const ContactUs = () => {
  const [data , setData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    phoneNumber:"",
    message:""

  })
  const [loading,setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState('');

  // console.log("data live",data);

  const handleChange = (e) =>{
    const {name,value} = e.target;
    setData((prev)=>({...prev,[name]:value}))

  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true);
    try {
const response = await axios.post("https://api.eltutor.in/api/website/add",data);

console.log('response',response);
setErrorMessage(response.data.message);
setData({
  firstName:"",
  lastName:"",
  email:"",
  phoneNumber:"",
  message:""
  
})
setLoading(false);

// alert(response.data.message);

      
    } catch (error) {
      console.log(
        "Failed To Submit Data"
      )
      setLoading(false);
      // alert(error.response.data.message);
      

      
    }

  }

  return (
    <>
      <div className="contact_us_green py-3 mt-3" id="ContactUs">
        <div className="container">
          <div class="text-center mb-4 main_contact">
            <h2 class="display-5 mb-4 fw-bold main_change_one">
              Contact{" "}
              <strong
                class="fw-bold"
                style={{
                  color: "#25AD0F",
                }}
              >
                Us
              </strong>
            </h2>
            <div className="d-flex justify-content-center">
              <p className="text-muted mb-4 col-lg-8 col-md-12 text-center contact ">
                We help you connect with your audience,build your brand,and
                acheive your business goals.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12 mt-5 mt-lg-0 Bid_contact">
              <div>
                <h2 className="contactus-head fw-bold fs-6">Contact Details</h2>
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3 ">
                    <i
                      className="me-3 p-2"
                      style={{
                        color: "#25AD0F",
                        backgroundColor: "#F0F9EE",
                      }}
                    >
                      <IoCall />
                    </i>
                    <a
                      href="tel:+9006912555"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <span>+91 9006912555</span>
                    </a>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <i
                      className="me-3 p-2"
                      style={{
                        color: "#25AD0F",
                        backgroundColor: "#F0F9EE",
                      }}
                    >
                      <IoMdMail />
                    </i>
                    <a
                      href="mailto:indrajeetpandey950@gmail.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <span>admin@eltutor.in</span>
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <i
                      className="me-3 p-2"
                      style={{
                        color: "#25AD0F",
                        backgroundColor: "#F0F9EE",
                      }}
                    >
                      <MdAddLocationAlt />
                    </i>
                    <span>
                      <span className="fs-8" style={{ fontWeight: "bold" }}>
                        IPELU TUTOR PRIVATE LIMITED
                      </span>
                      <br />
                      Trust colony Malaypur Near Jamui Rly. Station At+PO-
                      Malaypur Dist-Jamui,Bihar Pin 811313
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-12 main_form-box "
              style={{ backgroundColor: "#f3f3f3" }}
            >
              <form  onSubmit={handleSubmit} className="form-box">
                <div className="form-wrapper" style={{ padding: "15px" }}>
                  <div className="mb-4">
                    <h2 className="contactus-head fw-bold fs-6">
                      GOT ANY QUESTIONS
                    </h2>
                    <p
                      className="contactus-subhead fw-bold"
                      style={{
                        color: "#25AD0F",
                      }}
                    >
                      Send Us a Message
                    </p>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6 form_contact">
                      <label for="firstName" className="form-label input-title">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="First Name"
                        name="firstName"
                        placeholder="Enter First Name"
                        value={data.firstName}
                        onChange={handleChange}
                        required
                      

                      />
                    </div>
                    <div className="col-md-6 form_contact">
                      <label for="lastName" className="form-label input-title">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="Last Name"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={data.lastName}
                        onChange={handleChange}
                        required
                      />{" "}
                    </div>
                    <div className="col-md-6 form_contact">
                      <label htmlFor="email" className="form-label input-title">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control shadow-none"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={data.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 form_contact">
                      <label htmlFor="phone" className="form-label input-title">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control shadow-none"
                        id="phone"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        
                        maxLength="10"
                        
                        value={data.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 form_contact">
                      <label for="query" className="form-label input-title">
                        Message
                      </label>
                      <textarea
                        className="form-control shadow-none"
                        id="query"
                        rows="5"
                        name="message"
                        placeholder="Please enter your query..."
                        value={data.message}
                        onChange={handleChange}
                        required

                      
                      ></textarea>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn submit-btn shadow-none"
                      style={{
                        padding: "10px,70px",
                        fontWeight: "300",
                        backgroundColor: "#25AD0F",
                        color: "#ffffff",
                      }} 
                     
                    >
                      {loading ?"Sending...":"Send"}
                    </button>
                    {errorMessage && <p>{errorMessage}</p>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12">
        <img
          src="/assets/images/Frame.png"
          alt=""
          className="img-fluid"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
    </>
  );
};

export default ContactUs;
